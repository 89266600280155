@import 'styles/variables_deprecated';

.container {
  @include font-brand(16px, $black, 400, 16px);
  background-color: $white-off;
  padding: 5 * $unit;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  background: no-repeat url('/images/helix.png');
  background-position-x: 640px;
  background-position-y: top;
  background-size: 560px;
}

.logoType {
  @include font-brand(24px, $black, 400, 24px);
  text-transform: uppercase;
}

.content {
  align-items: center;
  display: flex;
  flex-grow: 1;

  margin-bottom: 4 * $unit;

  ol {
    list-style: decimal;
    // trying to align the left edge of bullets
    margin-left: 22px;
  }
}

.usernameContainer,
.input {
  letter-spacing: 0.8px;
  line-height: 1.4;
  // NOTE(ibash) must match inline style
  font-size: 32px;
}

.usernameContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: $unit;
  align-items: baseline;
}

.input {
  background: transparent;
  border: 0;
  outline: 0;
  border-bottom: 1px solid black;
  width: 100%;
}

.content button {
  @include font-brand(24px, $white, 400, 1);
  background-color: #596faf;
  text-transform: uppercase;

  border: none;
  letter-spacing: 1px;
  display: block;
  border-radius: 2 * $unit;

  // slightly shorter bottom padding so text is veritically centered
  padding: 16px 16px 12px 16px;

  cursor: pointer;
  margin-top: 4 * $unit;

  &:disabled {
    cursor: default;
    opacity: 0.65;
  }
}

.done {
  @include font-brand(24px, $black, 400, 24px);
}

@include mq($until: tablet) {
  .container {
    padding: 2 * $unit;
  }

  .usernameContainer,
  .input {
    font-size: 24px;
  }
}

// show / hide the form or done with transitions
.hidden,
.visible {
  // position absolute so the form and done can overlap
  position: absolute;
  transition: visibility 0s linear 300ms, opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-80px);
}

.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
