@import 'styles/variables_deprecated';

.container {
  @include font-brand(12px, $white, 400, 12px);
  user-select: none;
  margin: 0 (8 * $unit) (3 * $unit);
}

.strike {
  display: flex;
  align-items: center;
}

.hr {
  border: 0;
  border-top: 1px solid $white;
  display: block;
  flex-grow: 1;
  height: 1px;
  margin-right: 2.5 * $unit;
  padding: 0;
}
.links {
  display: flex;
  margin-top: 2 * $unit;
}

.links a {
  @include font-brand(16px, $white, 300, 18px);
  letter-spacing: 2px;
  margin-right: 4 * $unit;
}

.light,
.light .links a {
  color: $black;
}

.light hr {
  border-color: $black;
}
