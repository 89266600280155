@import 'styles/fonts.scss';
@import '~sass-mq';

$unit: 8px;

$black-04: rgba(0, 0, 0, 0.1);
$black-10: rgba(0, 0, 0, 0.1);
$black-15: rgba(0, 0, 0, 0.15);
$black-35: rgba(0, 0, 0, 0.35);
$black-50: rgba(0, 0, 0, 0.5);
$black-60: rgba(0, 0, 0, 0.6);
$black-65: rgba(0, 0, 0, 0.65);
$black-95: rgba(0, 0, 0, 0.95);
$black-off: rgba(37, 37, 37, 1);
$black: rgba(0, 0, 0, 1);
$blue-grey: rgba(248, 248, 250, 1);
$blue-light: rgba(237, 239, 248, 1);
$blue: rgba(97, 122, 255, 1);
$red: rgba(242, 95, 92, 1);
$shark: rgba(33, 35, 42, 1);
$white-off: rgba(248, 251, 246, 1);
$white: rgba(255, 255, 255, 1);

// ref: https://github.com/twbs/bootstrap/blob/5ec6400bd01057ab1d95285b5744dcf942192ad6/scss/_variables_deprecated#L396
$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

@mixin shadow {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05),
    0px 1.3px 4.5px rgba(0, 0, 0, 0.02), 0px 0.4px 1.3px rgba(0, 0, 0, 0.02);
}

@mixin font-sans($size: false, $color: false, $weight: false, $lh: false) {
  font-family: $font-family-sans-serif;
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin font-brand($size: false, $color: false, $weight: false, $lh: false) {
  font-family: 'Neue Haas Unica';
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: $color;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin font-regular {
  @include font-sans(14px, $black, 400, 20px);
}
