@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Black.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Medium.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-BoldItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtBlkIta.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtBlkIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtraBold.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-MediumItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtraBoldItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Italic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Light.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtLtIta.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtLtIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Bold.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ThinItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-LightItalic.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtraLight.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Regular.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-ExtraBlack.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-ExtraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-BoldItalic_1.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-BoldItalic_1.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Unica';
  src: url('/fonts/NeueHaasUnica-Thin.woff2') format('woff2'),
    url('/fonts/NeueHaasUnica-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
